<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="avatarSrc(userData.profile)"
          :text="avatarText(personName)"
          variant="light-primary"
          size="90px"
          rounded
        />
      </template>
      <div class="d-flex mb-1">
        <h4 class="m-0">
          {{ personName }}
        </h4>
        <h4
          class="ml-1 mb-0 cursor-pointer"
          :class="`text-${userActivationVariant}`"
          @click="$emit('verify', userData.id)"
        >
          {{ userActivationText }}
        </h4>
      </div>
      <div
        v-if="!disabledUploadAvatar"
        class="d-flex flex-wrap"
      >
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">
            {{ $t('update') }}
          </span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">
            {{ $t('remove') }}
          </span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
      <div
        v-if="!isProfile && $store.getters['profile/isAdmin']"
        class="d-flex mt-1 flex-wrap"
      >
        <b-button
          :variant="resolveUserActivationActionVariant(userData)"
          size="sm"
          :disabled="isVerifyBtnDisabled"
          class="d-flex justify-content-center"
          @click="verify"
        >
          <feather-icon :icon="resolveUserActivationActionIcon(userData)" />
          <span class="align-middle ml-50">
            {{ resolveUserActivationActionText(userData) }}
          </span>
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('country')"
            label-for="user-country"
          >
            <v-select
              v-model="updatableData.country_id"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="countryOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-country"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('country')"
            label-for="person-name"
          >
            <b-form-input
              id="person-name"
              v-model="updatableData.person_name"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('jobTitle')"
            label-for="job-title"
          >
            <b-form-input
              id="job-title"
              v-model="updatableData.post"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('email')"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="updatableData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('phoneNumber')"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="updatableData.phone"
              type="tel"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <b-form-checkbox
              id="buyer"
              v-model="updatableData.is_buyer"
            >
              {{ $t('buyer') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!--        <transition name="fade">-->
        <!--          <b-col-->
        <!--            v-if="updatableData.is_buyer"-->
        <!--            cols="12"-->
        <!--            md="4"-->
        <!--          >-->
        <!--            <b-form-group-->
        <!--              label="Category of goods"-->
        <!--              label-for="category-of-goods"-->
        <!--            >-->
        <!--              <b-form-input-->
        <!--                id="category-of-goods"-->
        <!--                v-model="updatableData.category_of_goods"-->
        <!--              />-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->
        <!--        </transition>-->

        <b-col
          cols="12"
        >
          <b-form-group>
            <b-form-checkbox
              id="supplier"
              v-model="updatableData.is_supplier"
            >
              {{ $t('supplier') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!--        <transition name="fade">-->
        <!--          <b-col-->
        <!--            v-if="updatableData.is_supplier"-->
        <!--            cols="12"-->
        <!--            md="4"-->
        <!--          >-->
        <!--            <b-form-group-->
        <!--              label="Field of activity"-->
        <!--              label-for="field-of-activity"-->
        <!--            >-->
        <!--              <b-form-input-->
        <!--                id="field-of-activity"-->
        <!--                v-model="updatableData.field_of_activity"-->
        <!--              />-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->
        <!--        </transition>-->
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      :disabled="isSaveDisabled"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateUserData"
    >
      {{ $t('saveChanges') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="refreshUpdatableData"
    >
      {{ $t('reset') }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import vSelect from 'vue-select';
import { ref, computed, watch } from '@vue/composition-api';
import store from '@/store';
import useUsersList from "@/views/apps/user/users-list/useUsersList";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isProfile: {
      type: Boolean,
      default: false,
    },
    disabledUploadAvatar: {
      type: Boolean,
      default: false,
    },
    disabledEmailVerify: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    personName() {
      return this.userData.profile?.person_name;
    },
  },
  setup(props, { emit }) {
    const userActivationVariant = computed(() => (props.userData.is_verified ? 'success' : 'danger'));
    const userActivationText = computed(() => (
      props.userData.is_verified ? 'Active' : props.disabledEmailVerify ? 'Need to confirm e-mail' : 'Resend activation e-mail'
    ));
    const getCountries = computed(() => store.state.app.availableCountries);

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const inputImageRenderer = () => {
      const newForm = new FormData();
      newForm.set('avatar', refInputEl.value.files[0]);
      emit('updateAvatar', newForm);
    };

    const avatarSrc = value => value && value.avatar_url;

    const countryOptions = computed(() => getCountries.value.map(country => ({ value: country.id, label: country.name })));

    const isVerifyBtnDisabled = computed(() => {
      const {
        profile,
        company,
        agreement_signed,
      } = props.userData;
      return !profile?.country || !profile?.phone || !profile?.person_name
          || !company?.tax_number || !company?.company_name || !company?.bank_account
          || !company?.unp || !company?.swift || !agreement_signed;
    });

    const updatableData = ref(null);

    const refreshUpdatableData = () => {
      const {
        profile,
        email,
        roles,
        id: userId,
      } = props.userData;
      const isBuyer = roles?.some(({ id }) => (id === 2)) || false;
      const isSupplier = roles?.some(({ id }) => (id === 3)) || false;

      updatableData.value = {
        country_id: profile?.country?.id || '',
        person_name: profile?.person_name || '',
        job: '',
        email,
        id: userId,
        phone: profile?.phone || '',
        post: profile?.post || '',
        is_supplier: isSupplier,
        is_buyer: isBuyer,
        field_of_activity: '',
        category_of_goods: '',
        roles: roles?.filter(item => item?.id === 2 || item?.id === 3) || [],
      };
    };

    refreshUpdatableData();
    watch(() => props.userData, refreshUpdatableData);

    const isSaveDisabled = computed(() => !updatableData.value.email?.length
        || !updatableData.value.person_name?.length
        || !updatableData.value.country_id
        || !updatableData.value.phone?.length
        || !updatableData.value.post?.length);

    const updateUserData = () => {
      const {
        email,
        person_name,
        country_id,
        phone,
        post,
        is_supplier,
        is_buyer,
      } = updatableData.value;

      const data = {
        email,
        'profile[person_name]': person_name,
        'profile[country_id]': country_id,
        'profile[phone]': phone,
        'profile[post]': post,
      };

      const params = new URLSearchParams(data);
      if (is_supplier) params.append('roles[]', 'supplier');
      if (is_buyer) params.append('roles[]', 'buyer');
      if (!is_supplier && !is_buyer) {
        params.set('roles[]', '');
      }
      emit('updateData', params);
    };

    const {
      verifyUser,
      resolveUserActivationActionVariant,
      resolveUserActivationActionText,
      resolveUserActivationActionIcon,
    } = useUsersList();

    const verify = async () => {
      await verifyUser(props.userData);
      emit('updateProfile');
    };

    return {
      isVerifyBtnDisabled,
      avatarSrc,
      avatarText,
      updatableData,
      refreshUpdatableData,
      updateUserData,
      isSaveDisabled,

      countryOptions,

      verify,
      resolveUserActivationActionVariant,
      resolveUserActivationActionText,
      resolveUserActivationActionIcon,

      userActivationVariant,
      userActivationText,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
